// Migrated
<template>
  <LayoutWrapper class="block">
    <template #header>
      <Header />
    </template>
    <template #banner>
      <Banner />
    </template>
    <template #hero>
      <Hero />
    </template>
    <template #sidebar>
      <Sidebar />
    </template>
    <template #content>
      <MemberNavTabs
        animated
        @on-tab-change="updateActiveTab"
      />
      <slot />
    </template>
    <template #footer>
      <div
        ref="extraFooterRef"
        class="w-full inline-block print-fullpage print-break-before mt-4 md:mt-4"
      />
      <LazyFooter />
    </template>
  </LayoutWrapper>
</template>

<script setup>
const route = useRoute()
const { urls: localeURLs } = useLocale()
const { t } = useI18n()
const userStore = useUserStore()

const extraFooterRef = ref()
const slug = route.params.slug
const activeTab = findKeyBySlug(slug, localeURLs.authSlug)
userStore.SET_MEMBER_PAGE_TAB(activeTab)

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,
  link: () => [
    // { rel: 'icon', type: 'image/x-icon', href: t('favicon') },
    {
      rel: 'canonical',
      href: () => `${localeURLs.BASE_URL}$`,
    },
  ],
  meta: () => [
    { hid: 'author', name: 'author', content: t('siteTitleBrand') },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `%s - ${t('siteTitleBrand')}`,
    },
  ],

})

const updateActiveTab = (newActiveTab) => {
  userStore.SET_MEMBER_PAGE_TAB(newActiveTab)
}
</script>
